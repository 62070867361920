import React, {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  const OrganizationPage = lazy(() => import('../pages/organization/OrganizationPage'))
  const EmployeePage = lazy(() => import('../modules/employees/EmployeePage'))
  const ChannelPage = lazy(() => import('../modules/channels/ChannelPage'))
  const ContractPage = lazy(() => import('../pages/contract/ContractPage'))
  const MasterdataPage = lazy(() => import('../pages/masterdata/MasterdataPage'))
  const ClaimPage = lazy(() => import('../pages/claim/ClaimPage'))
  const PromotionPage = lazy(() => import('../pages/promotion/PromotionPage'))
  const PromotionCodePage = lazy(() => import('../pages/promotion/PromotionCodePage'))
  const CommissionPage = lazy(() => import('../pages/commission/CommissionPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='organization/*'
          element={
            <SuspensedView>
              <OrganizationPage />
            </SuspensedView>
          }
        />
        <Route
          path='promotion/*'
          element={
            <SuspensedView>
              <PromotionPage />
            </SuspensedView>
          }
        />
        <Route
          path='promotion-code/*'
          element={
            <SuspensedView>
              <PromotionCodePage />
            </SuspensedView>
          }
        />
        <Route
          path='employee/*'
          element={
            <SuspensedView>
              <EmployeePage />
            </SuspensedView>
          }
        />
        <Route
          path='channel/*'
          element={
            <SuspensedView>
              <ChannelPage />
            </SuspensedView>
          }
        />
        <Route
          path='contract/*'
          element={
            <SuspensedView>
              <ContractPage />
            </SuspensedView>
          }
        />
        <Route
          path='masterdata/*'
          element={
            <SuspensedView>
              <MasterdataPage />
            </SuspensedView>
          }
        />
        <Route
          path='claim/*'
          element={
            <SuspensedView>
              <ClaimPage />
            </SuspensedView>
          }
        />
        <Route
          path='commission/*'
          element={
            <SuspensedView>
              <CommissionPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
