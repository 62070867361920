import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {toAbsoluteUrl} from '@/_metronic/helpers'
import {AxiosError} from 'axios'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Sai định dạng email').required('Vui lòng nhập dữ liệu'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
            navigate('/')
          })
          .catch((error) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            if (error instanceof AxiosError) {
              setStatus(error?.response?.data?.message)
            }
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
      style={{
        borderRadius: 4,
        background: '#FFFFFF',
        boxShadow: '0px 0px 5px 0px rgba(18, 38, 63, 0.05)',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <div
        className='mb-10'
        style={{
          backgroundImage: `url(https://phwi-static.s3.amazonaws.com/1696825768827-528130658.png)`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          padding: 20,
        }}
      >
        <h1 className='fw-bolder mb-3' style={{color: '#556EE6'}}>
          Quên mật khẩu ?
        </h1>
        <div className='fw-semibold fs-6' style={{color: '#556EE6'}}>
          Vui lòng nhập email để nhận link khôi phục mật khẩu
        </div>
      </div>

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Gửi yêu cầu đổi mật khẩu thành công. Vui lòng kiểm tra email.
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8' style={{width: '70%'}}>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': (formik.touched.email && formik.errors.email) || hasErrors},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {((formik.touched.email && formik.errors.email) || hasErrors) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email || formik.status}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
          style={{width: '50%', borderRadius: 50}}
        >
          {!loading && <span className='indicator-label'>Xác nhận</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary'
            style={{width: '50%', borderRadius: 50, marginTop: 20}}
          >
            Quay lại
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
