/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {SetPasswordFromMail} from '../modules/auth/components/SetPasswordFromMail'
import {AuthLayout} from '../modules/auth/AuthLayout'
import {verifyToken} from '../modules/auth/core/_requests'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)

const AppRoutes: FC = () => {
  const {auth} = useAuth()
  const [userFromMail, setUserFromMail] = useState({})
  const [errorVerify, setErrorVerify] = useState(false)
  const token = urlParams.get('token')

  useEffect(() => {
    if (token) {
      verifyToken(token)
        .then((res) => {
          if (res?.status >= 200 && res?.status <= 300) {
            setUserFromMail({...res?.data?.data, token})
          } else {
            setErrorVerify(true)
          }
        })
        .catch((error) => {
          setErrorVerify(true)
        })
    }
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='logout' element={<Logout />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route element={<AuthLayout />}>
            <Route
              path='verify-email'
              element={
                <SetPasswordFromMail userFromMail={userFromMail} errorVerify={errorVerify} />
              }
            />
          </Route>
          {auth ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
