import {Modal} from 'react-bootstrap'

import {useApp} from '@/app/core/AppContext'

export const AppDialog = () => {
  const {dialog, hideDialog} = useApp()

  return (
    <Modal show={dialog?.display} onHide={hideDialog} size={dialog?.size}>
      {dialog?.title && (
        <Modal.Header closeButton>
          <Modal.Title>{dialog?.title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body style={{padding: 0}}>{dialog?.content}</Modal.Body>
    </Modal>
  )
}
