/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {Link} from 'react-router-dom'

import {useAuth} from '@/app/modules/auth'

import {useLayout} from '../../core'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {MenuTab} from './Tabs/MenuTab'
import {useMemo} from 'react'

const AsideDefault = () => {
  const {classes} = useLayout()
  const {auth} = useAuth()

  const organization = useMemo(
    () => auth?.user.organizations?.find((o) => o.id === auth.selectedOrganization),
    [auth?.user]
  )

  return (
    <div
      id='kt_aside'
      className={clsx('aside aside-extended', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      {/* <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
      </div> */}
      <div className='aside-secondary d-flex flex-row-fluid'>
        <div className='aside-workspace my-5 p-5' id='kt_aside_wordspace'>
          <div
            className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto pb-4'
            id='kt_aside_logo'
          >
            <Link to='/dashboard'>
              <img
                src={organization?.logo || toAbsoluteUrl('/logo.webp')}
                alt='logo'
                className='h-35px'
              />
            </Link>
          </div>
          <div className='d-flex h-100 flex-column'>
            <div
              className='flex-column-fluid hover-scroll-y'
              data-kt-scroll='true'
              data-kt-scroll-activate='true'
              data-kt-scroll-height='auto'
              data-kt-scroll-wrappers='#kt_aside_wordspace'
              data-kt-scroll-dependencies='#kt_aside_secondary_footer'
              data-kt-scroll-offset='0px'
            >
              <div className='tab-content'>
                <div
                  className='tab-pane fade active show'
                  id={`kt_aside_nav_tab_menu`}
                  role='tabpanel'
                >
                  <MenuTab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end::Secondary */}
      {/* begin::Aside Toggle */}
      <button
        id='kt_aside_toggle'
        className={clsx(
          'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
          classes.asideToggle.join(' ')
        )}
        data-kt-toggle='true'
        data-kt-toggle-state='active'
        data-kt-toggle-target='body'
        data-kt-toggle-name='aside-minimize'
        style={{marginBottom: '1.35rem'}}
      >
        <KTIcon iconName='arrow-left' className='fs-2 rotate-180' />
      </button>
    </div>
  )
}

export {AsideDefault}
