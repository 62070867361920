import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'

import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {ThemeModeProvider} from '../_metronic/partials'
import {AuthInit} from './modules/auth'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import {AppProvider} from './core/AppContext'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <ThemeModeProvider>
          <AppProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </AppProvider>
          <ToastContainer />
        </ThemeModeProvider>
      </LayoutProvider>
    </Suspense>
  )
}

export {App}
