/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {UserMenu} from '@/app/modules/profile/components/UserMenu'
import {ThemeModeSwitcher} from '../../../partials'

const Topbar: FC = () => (
  <div className='d-flex flex-shrink-0'>
    {/* begin::Theme mode */}
    <div className='d-flex align-items-center ms-3'>
      <ThemeModeSwitcher toggleBtnClass=' flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px' />
    </div>
    {/* end::Theme mode */}
    <div className='d-flex align-items-center ms-3'>
      <UserMenu />
    </div>

    {/* CHAT */}
    {/* <div className='d-flex align-items-center ms-3'>
      <div
        className='btn btn-icon btn-primary w-40px h-40px pulse pulse-white'
        id='kt_drawer_chat_toggle'
      >
        <KTIcon iconName='message-text-2' className='fs-2' />
        <span className='pulse-ring' />
      </div>
    </div> */}
  </div>
)

export {Topbar}
