import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {ID} from '@/_metronic/helpers'

const API_URL = process.env.REACT_APP_API_GW
const PREFIX = 'auth/v1/auth'
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/${PREFIX}/employee/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/${PREFIX}/forgot-password`
export const VERIFY_RECAPTCHA = 'https://www.google.com/recaptcha/api/siteverify'
export const VERIFY_TOKEN = `${API_URL}/${PREFIX}/verify-account-token`
export const SET_NEW_PASSWORD = `${API_URL}/${PREFIX}/set-new-password`
export const GET_USER_ORGANIZATIONS = 'usergroup/public/organizations/phone'
export const CHANGE_PASSWORD = 'auth/v1/users/change-password'
export const ADMIN_CHANGE_PASSWORD = 'auth/v1/admin/change-password'
// Server should return AuthModel

export function login(phone: string, password: string) {
  return axios
    .post<AuthModel>(LOGIN_URL, {
      phone,
      password,
    })
    .then((res) => res.data)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export function verifyRecaptcha(secret: string, response: string) {
  return axios.post(VERIFY_RECAPTCHA, {
    secret,
    response: response,
  })
}

export function verifyToken(token: string) {
  return axios.post(VERIFY_TOKEN, {
    token,
  })
}

export function setNewPassword(token: string | null, newPassword: string) {
  return axios.post(SET_NEW_PASSWORD, {
    token,
    newPassword,
  })
}

export function getUserOrganizations(phone: string) {
  return axios.get(`${GET_USER_ORGANIZATIONS}/${phone}`)
}

export function changePassword(data: {
  token: string | ''
  currentPassword: string
  newPassword: string
}) {
  return axios.patch(
    CHANGE_PASSWORD,
    {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    },
    {
      headers: {
        Authorization: data.token,
      },
    }
  )
}

export function adminChangePassword(data) {
  return axios.patch(
    ADMIN_CHANGE_PASSWORD,
    {
      userId: data.userId,
      newPassword: data.newPassword,
      email: data.email,
      orgId: data.orgId,
      orgName: data.orgName,
      orgUrl: data.orgUrl,
      fullName: data.fullName,
    },
    {
      headers: {
        Authorization: data.token,
      },
    }
  )
}

export function getUserPermissions(organizationId: string) {
  return axios.get('auth/v1/users/permissions', {
    headers: {
      'x-org-id': organizationId,
    },
  })
}
