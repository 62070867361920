import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useNavigate, useLocation} from 'react-router-dom'
import {ErrorMessage, Form, FormikProvider, useFormik} from 'formik'
import {changePassword} from '../core/_requests'
import {AxiosError} from 'axios'
import {FormFieldPassword} from '@/app/components/formfield-password/FormFieldPassword'

interface propState {
  token: string
}

const initialValues = {
  password: '',
  newPassword: '',
  confirmPassword: '',
}

const changePasswordSchema = Yup.object().shape({
  password: Yup.string().required('Vui lòng nhập dữ liệu'),
  newPassword: Yup.string()
    .required('Vui lòng nhập dữ liệu')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
      'Mật khẩu phải bao gồm 8-15 ký tự, có ít nhất 1 chữ hoa, 1 chữ thường và 1 ký tự đặc biệt'
    ),
  confirmPassword: Yup.string()
    .required('Vui lòng nhập dữ liệu')
    .oneOf([Yup.ref('newPassword')], 'Cần trùng với mật khẩu mới'),
})

export function ChangePassword() {
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  let {token} = location.state as propState

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      const data = {
        token: token ? token : '',
        currentPassword: values.password,
        newPassword: values.newPassword,
      }
      setTimeout(() => {
        changePassword(data)
          .then(() => {
            navigate('/auth')
          })
          .catch((error) => {
            setSubmitting(false)
            if (error instanceof AxiosError) {
              formik.setFieldError('password', error?.response?.data?.message)
            }
          })
          .finally(() => {
            setLoading(false)
          })
      }, 1000)
    },
  })

  const enableRequirePass = (value, type) => {
    const checkAlp = /^(?=.*?[A-Z])(?=.*?[a-z])/
    const checkNum = /^(?=.*?[0-9])/
    const checkSpeCharactor = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    if (
      (value?.length >= 8 && type == 'length') ||
      (checkAlp.test(value) && type == 'alp') ||
      (checkNum.test(value) && type == 'num') ||
      (checkSpeCharactor.test(value) && type == 'speCharactor')
    ) {
      return '#666666'
    }
    return '#A3A3A3'
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <div
          className='mb-10'
          style={{
            backgroundImage: `url(https://phwi-static.s3.amazonaws.com/1696825768827-528130658.png)`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            padding: 20,
          }}
        >
          <h1 className='fw-bolder mb-3' style={{color: '#556EE6'}}>
            Chào mừng bạn,
          </h1>
          <div className='fw-semibold fs-6' style={{color: '#556EE6'}}>
            Vui lòng nhập mật khẩu cho lần đăng nhập đầu tiên
          </div>
        </div>

        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-3' style={{width: '70%'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <label className='form-label text-gray-600 fs-6 mb-0 required'>Mật khẩu hiện tại</label>
          </div>
          <FormFieldPassword
            {...formik.getFieldProps('password')}
            className={clsx('form-control bg-transparent')}
          />
          <ErrorMessage name='password' component='span' className='message-invalid' />
        </div>
        <div className='fv-row mb-3' style={{width: '70%'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <label className='form-label text-gray-600 fs-6 mb-0 required'>Mật khẩu mới</label>
          </div>
          <FormFieldPassword
            {...formik.getFieldProps('newPassword')}
            className={clsx('form-control bg-transparent')}
          />
          <ErrorMessage name='newPassword' component='span' className='message-invalid' />
        </div>
        <div
          className='fv-row mb-3'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ul>
            <li style={{color: enableRequirePass(formik.values.newPassword, 'length')}}>
              Ít nhất 8 kí tự
            </li>
            <li style={{color: enableRequirePass(formik.values.newPassword, 'num')}}>
              Một số (vd. 1234)
            </li>
          </ul>
          <ul>
            <li style={{color: enableRequirePass(formik.values.newPassword, 'alp')}}>
              Sử dụng ít nhất 1 chữ viết hoa, 1 chữ viết thường (e.g. Aa)
            </li>
            <li style={{color: enableRequirePass(formik.values.newPassword, 'speCharactor')}}>
              Sử dụng 1 kí tự đặc biệt (v.d. !@#$)
            </li>
          </ul>
        </div>
        <div className='fv-row mb-3' style={{width: '70%'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <label className='form-label text-gray-600 fs-6 mb-0 required'>
              Nhập lại mật khẩu mới
            </label>
          </div>
          <FormFieldPassword
            {...formik.getFieldProps('confirmPassword')}
            className={clsx('form-control bg-transparent')}
          />
          <ErrorMessage name='confirmPassword' component='span' className='message-invalid' />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
            style={{width: '50%', borderRadius: 50}}
          >
            {!loading && <span className='indicator-label'>Xác nhận</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </Form>
    </FormikProvider>
  )
}
