import {toAbsoluteUrl} from '@/_metronic/helpers'
import {useAuth} from '../../auth'

const UserMenu = () => {
  const {auth, logout, switchOrganization} = useAuth()
  return (
    <div
      className='aside-footer d-flex flex-column align-items-center flex-column-auto'
      id='kt_aside_footer'
    >
      {/* begin::User */}
      <div className='d-flex align-items-center' id='kt_header_user_menu_toggle'>
        {/* begin::Menu wrapper */}
        <div
          className='cursor-pointer symbol symbol-40px'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
        >
          <img src={toAbsoluteUrl('/images/avatar.jpg')} alt='avatar' />
        </div>
        {/* end::Menu wrapper */}
        <div
          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
          data-kt-menu='true'
        >
          <div className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-5'>
                <img src={toAbsoluteUrl('/images/avatar.jpg')} alt='avatar' />
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {auth?.user?.phone}
                </div>
                <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                  {auth?.user?.email}
                </a>
              </div>
            </div>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-5'>
            {(auth?.user?.organizations?.length ?? 0) > 1 && (
              <a href='#' onClick={switchOrganization} className='menu-link px-5'>
                Chọn tổ chức khác
              </a>
            )}
            <a href='#' onClick={logout} className='menu-link px-5'>
              Đăng xuất
            </a>
          </div>
        </div>
      </div>
      {/* end::User */}
    </div>
  )
}

export {UserMenu}
