import {Field} from 'formik'
import {useState} from 'react'
import {KTIcon} from '@/_metronic/helpers'
import './FormFieldPassword.scss'

export const FormFieldPassword = (props) => {
  const [fieldType, setFieldType] = useState<string>('password')

  const handleToggle = () => {
    setFieldType(fieldType === 'password' ? 'text' : 'password')
  }
  return (
    <div className='form-field-password'>
      <Field {...props} type={fieldType} />
      <button type='button' className='btn-toggle' onClick={handleToggle}>
        <KTIcon
          className='fs-2 text-gray-800'
          iconName={`${fieldType === 'password' ? 'eye-slash' : 'eye'}`}
        />
      </button>
    </div>
  )
}
