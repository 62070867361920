import {useNavigate} from 'react-router-dom'

import {ID, toAbsoluteUrl} from '@/_metronic/helpers'

import {useAuth} from '../core/Auth'
import {AuthModel} from '../core/_models'

export const OrganizationSelected = () => {
  const {auth, saveAuth, logout} = useAuth()
  const navigate = useNavigate()

  const selectOrganization = (organizationId: ID) => () =>
    saveAuth({
      ...auth,
      selectedOrganization: organizationId,
    } as AuthModel)

  const handleBack = () => {
    logout()
    navigate('/auth')
  }

  return (
    <div className='container mt-20 d-flex justify-content-center'>
      <div className='w-100 mw-600px p-10 bg-white rounded'>
        <div
          className='block-header mb-10 p-4 bg-light-info text-info'
          style={{
            background: `url(${toAbsoluteUrl('/images/block-header.png')}) no-repeat center right`,
          }}
        >
          <h3 className='mb-2 text-info'>Chào bạn,</h3>
          <p>Vui lòng chọn tổ chức mà bạn muốn đăng nhập</p>
        </div>
        {auth?.user?.organizations?.length ? (
          <>
            {auth?.user?.organizations?.map((organization) => (
              <div
                className='border border-gray-300 mb-6 p-2 cursor-pointer rounded bg-hover-light overflow-hidden'
                key={`organization-${organization.id}`}
                onClick={selectOrganization(organization.id)}
              >
                <div className='d-flex align-items-center'>
                  <div className='me-5'>
                    <img src={organization.logo} width={60} height={60} />
                  </div>
                  <div className=''>
                    <small className='fs-6 text-gray-500'>
                      {organization.type == 'AGENT'
                        ? 'Đại lý'
                        : organization.type == 'SUPPLIER'
                        ? 'Công ty'
                        : 'Doanh nghiệp'}
                    </small>
                    <div className='fs-4'>{organization.name}</div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='text-center mb-10'>
            Chúng tôi không tìm thấy doanh nghiệp của bạn. <br />
            Vui lòng liên hệ với Quản trị viên doanh nghiệp để được hỗ trợ.
          </div>
        )}
        <div className='d-flex justify-content-center'>
          <button className='btn btn-primary' onClick={handleBack}>
            Quay lại
          </button>
        </div>
      </div>
    </div>
  )
}
