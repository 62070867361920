/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <div style={{paddingBottom: 20}}>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='color-swatch'
        title={'DASHBOARD'}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      <AsideMenuItem to='' title='Dashboard' icon='chart-simple' fontIcon='bi-app-indicator' />
      <AsideMenuItemWithSub to='' title='Quản trị hệ thống' icon='data' fontIcon='bi-diagram'>
        <AsideMenuItemWithSub to='' title='Cơ sở dữ liệu dùng chung' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='/masterdata/address' title='Địa danh' hasBullet={true} />
            <AsideMenuItem to='' title='Xe cơ giới' hasBullet={true} />
            <AsideMenuItemWithSub to='' title='Hàng không' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem to='/masterdata/airline' title='Hãng hàng không' hasBullet={true} />
                <AsideMenuItem to='/masterdata/airport' title='Sân bay' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
            <AsideMenuItem to='/masterdata/hospital' title='Bệnh viện' hasBullet={true} />
            <AsideMenuItem to='/masterdata/bank' title='Ngân hàng' hasBullet={true} />
            <AsideMenuItem to='/masterdata/unit' title='Đơn vị' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Hệ thống chữ kí số' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Trực tiếp' hasBullet={true} />
            <AsideMenuItem to='' title='Gián tiếp' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItem to='' title='Hệ thống eMail' hasBullet={true} />
        <AsideMenuItem to='' title='Hệ thống SMS' hasBullet={true} />
        <AsideMenuItem to='' title='Hệ thống tổng đài' hasBullet={true} />
        <AsideMenuItem to='' title='Hệ thống Livechat & Chatbot' hasBullet={true} />
        <AsideMenuItemWithSub to='' title='Hệ thống thanh toán' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Ví điện tử' hasBullet={true} />
            <AsideMenuItem to='' title='Ngân hàng' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Hệ thống quản trị đối tác' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Đăng kiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Phạt nguội' hasBullet={true} />
            <AsideMenuItem to='' title='Garage' hasBullet={true} />
            <AsideMenuItem to='' title='Bệnh viện' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='/organization' title='Quản trị doanh nghiệp' icon='people' fontIcon='bi-diagram'>
        <AsideMenuItemWithSub to='/organization' title='Cấu trúc doanh nghiệp' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to='/organization/overview'
              title='Danh sách doanh nghiệp'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/organization/structure'
              title='Sơ đồ doanh nghiệp'
              hasBullet={true}
            />
            <AsideMenuItemWithSub to='' title='Thông tin doanh nghiệp' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem to='/organization/department' title='Phòng ban' hasBullet={true} />
                <AsideMenuItem to='/organization/role' title='Chức danh' hasBullet={true} />
                <AsideMenuItem to='/organization/source' title='Nguồn bán' hasBullet={true} />
                <AsideMenuItem to='/organization/certificate' title='Chữ ký số' hasBullet={true} />
                <AsideMenuItem to='/organization/email' title='Cấu hình email' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Đối tác/Đại lý' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Định giá' hasBullet={true} />
            <AsideMenuItem to='' title='Tái/ Đồng' hasBullet={true} />
            <AsideMenuItem to='' title='Bồi thường' hasBullet={true} />
            <AsideMenuItem to='' title='Bán hàng' hasBullet={true} />
            <AsideMenuItem to='' title='Dịch vụ khác' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItem to='' title='Phân quyền' hasBullet={true} />
        <AsideMenuItem to='/employee' title='Danh sách nhân viên' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='' title='Quản trị nghiệp vụ' icon='note-2' fontIcon='bi-diagram'>
        {/* <AsideMenuItemWithSub to='/channel' title='Kênh bán' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='/channel/overview' title='Danh sách kênh bán' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub> */}
        <AsideMenuItemWithSub to='/contract' title='Hợp đồng bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='/contract/travel' title='Du lịch' hasBullet={true} />
            <AsideMenuItem to='' title='Xe máy' hasBullet={true} />
            <AsideMenuItem to='/contract/overview' title='Xe cơ giới' hasBullet={true} />
            <AsideMenuItem to='' title='Con người' hasBullet={true} />
            <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
            <AsideMenuItem to='' title='Kỹ thuật' hasBullet={true} />
            <AsideMenuItem to='' title='Trách nhiệm' hasBullet={true} />
            <AsideMenuItem to='' title='Hàng hoá' hasBullet={true} />
            <AsideMenuItem to='' title='Tàu thuyền' hasBullet={true} />
            <AsideMenuItem to='' title='Trễ chuyến bay' hasBullet={true} />
            <AsideMenuItem to='' title='Thiết bị điện tử' hasBullet={true} />
            <AsideMenuItem to='' title='Sản phẩm mới' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Tái tục' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Xe máy' hasBullet={true} />
            <AsideMenuItem to='' title='Xe cơ giới' hasBullet={true} />
            <AsideMenuItem to='' title='Con người' hasBullet={true} />
            <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
            <AsideMenuItem to='' title='Kỹ thuật' hasBullet={true} />
            <AsideMenuItem to='' title='Trách nhiệm' hasBullet={true} />
            <AsideMenuItem to='' title='Hàng hoá' hasBullet={true} />
            <AsideMenuItem to='' title='Tàu thuyền' hasBullet={true} />
            <AsideMenuItem to='' title='Trễ chuyến bay' hasBullet={true} />
            <AsideMenuItem to='' title='Thiết bị điện tử' hasBullet={true} />
            <AsideMenuItem to='' title='Sản phẩm mới' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Tài liệu' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Giấy chứng nhận điện tử' hasBullet={true} />
            <AsideMenuItem to='' title='Trí thức doanh nghiệp (ECM)' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Yêu cầu' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='/claim/overview' title='Bồi thường' hasBullet={true} />
            <AsideMenuItem to='' title='Khiếu nại' hasBullet={true} />
            <AsideMenuItem to='' title='Tư vấn thông tin' hasBullet={true} />
            <AsideMenuItem to='/contract/amendment-request' title='SĐBS/Hủy hợp đồng' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Cấu hình sản phẩm bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Xe máy' hasBullet={true} />
            <AsideMenuItem to='' title='Xe cơ giới' hasBullet={true} />
            <AsideMenuItem to='' title='Con người' hasBullet={true} />
            <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
            <AsideMenuItem to='' title='Kỹ thuật' hasBullet={true} />
            <AsideMenuItem to='' title='Trách nhiệm' hasBullet={true} />
            <AsideMenuItem to='' title='Hàng hoá' hasBullet={true} />
            <AsideMenuItem to='' title='Tàu thuyền' hasBullet={true} />
            <AsideMenuItem to='' title='Trễ chuyến bay' hasBullet={true} />
            <AsideMenuItem to='' title='Thiết bị điện tử' hasBullet={true} />
            <AsideMenuItem to='' title='Sản phẩm mới' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Định phí bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Xe máy' hasBullet={true} />
            <AsideMenuItem to='' title='Xe cơ giới' hasBullet={true} />
            <AsideMenuItem to='' title='Con người' hasBullet={true} />
            <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
            <AsideMenuItem to='' title='Kỹ thuật' hasBullet={true} />
            <AsideMenuItem to='' title='Trách nhiệm' hasBullet={true} />
            <AsideMenuItem to='' title='Hàng hoá' hasBullet={true} />
            <AsideMenuItem to='' title='Tàu thuyền' hasBullet={true} />
            <AsideMenuItem to='' title='Trễ chuyến bay' hasBullet={true} />
            <AsideMenuItem to='' title='Thiết bị điện tử' hasBullet={true} />
            <AsideMenuItem to='' title='Sản phẩm mới' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Chào phí sản phẩm bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Xe máy' hasBullet={true} />
            <AsideMenuItem to='' title='Xe cơ giới' hasBullet={true} />
            <AsideMenuItem to='' title='Con người' hasBullet={true} />
            <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
            <AsideMenuItem to='' title='Kỹ thuật' hasBullet={true} />
            <AsideMenuItem to='' title='Trách nhiệm' hasBullet={true} />
            <AsideMenuItem to='' title='Hàng hoá' hasBullet={true} />
            <AsideMenuItem to='' title='Tàu thuyền' hasBullet={true} />
            <AsideMenuItem to='' title='Trễ chuyến bay' hasBullet={true} />
            <AsideMenuItem to='' title='Thiết bị điện tử' hasBullet={true} />
            <AsideMenuItem to='' title='Sản phẩm mới' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Định giá đối tượng bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Xe cơ giới' hasBullet={true} />
            <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Thẻ bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Cấu hình thẻ bảo hiểm' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Quản lý khuyến mãi' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='/promotion/program' title='Chương trình khuyến mãi' hasBullet={true} />
            <AsideMenuItem to='/promotion-code' title='Mã khuyến mãi' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Hoa hồng' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='/commission/overview' title='Cấu hình' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ đại lý' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Hiệu quả KPI' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Cấu hình KPI chi nhánh' hasBullet={true} />
            <AsideMenuItem to='' title='Cấu hình KPI đại lý' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Quản trị luồng vận hành' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Luồng chào phí' hasBullet={true} />
            <AsideMenuItem to='' title='Luồng duyệt HDBH' hasBullet={true} />
            <AsideMenuItem to='' title='Luồng duyệt tài' hasBullet={true} />
            <AsideMenuItem to='' title='Luồng duyệt bồi thường' hasBullet={true} />
            <AsideMenuItem to='' title='Chuyển tái' hasBullet={true} />
            <AsideMenuItem to='' title='Khác' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItem to='' title='Quản trị nhân sự' hasBullet={true} />
        <AsideMenuItem to='' title='Quản trị lương thưởng' hasBullet={true} />
        <AsideMenuItemWithSub to='' title='Quản trị quan hệ khách hàng' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Danh sách khách hàng' hasBullet={true} />
            <AsideMenuItem to='' title='Cấu hình xếp hạng' hasBullet={true} />
            <AsideMenuItem to='' title='Danh sách chiến dịch' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Thẩm định bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Nhóm rủi ro' hasBullet={true} />
            <AsideMenuItem to='' title='Đánh giá' hasBullet={true} />
            <AsideMenuItem to='' title='Trình duyệt phí' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Đồng bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Cấu hình đồng bảo hiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Tái bảo hiểm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Nhận tái cố định' hasBullet={true} />
            <AsideMenuItem to='' title='Nhận tái tạm thời' hasBullet={true} />
            <AsideMenuItem to='' title='Nhượng tái' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ nhà tái' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='' title='Báo cáo' icon='chart-simple-3' fontIcon='bi-diagram'>
        <AsideMenuItemWithSub to='' title='Báo cáo nghiệp vụ' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='D/S hợp đồng đến hạn nộp phí' hasBullet={true} />
            <AsideMenuItem to='' title='D/S hợp đồng tái tục' hasBullet={true} />
            <AsideMenuItem to='' title='D/S hợp đồng đã phát hành hoá đơn GTGT' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='D/S hợp đồng chưa phát hành hoá đơn GTGT'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Công nợ theo HĐBH' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ phí giám định' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ hoa hồng đại lý' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ bồi thường' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo kinh doanh' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo Doanh thu' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Hoa hồng' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Bồi thường' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Giám định' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Kế toán' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Bộ tài chính' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Quản trị' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Kế hoạch' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo Tái/Đồng bảo hiểm' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo rủi ro' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Theo khách hàng' hasBullet={true} />
            <AsideMenuItem to='' title='Theo tỉnh thành' hasBullet={true} />
            <AsideMenuItem to='' title='Theo chi nhánh' hasBullet={true} />
            <AsideMenuItem to='' title='Theo năm' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Quản trị hệ thống
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub to='/channel' title='Kênh bán' icon='abstract-44' fontIcon='bi-diagram'>
        <AsideMenuItem to='/channel/overview' title='Danh sách kênh bán' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/organization'
        title='Tổ chức'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <AsideMenuItem to='/organization/structure' title='Cấu trúc tổ chức' hasBullet={true} />
        <AsideMenuItem to='/organization/overview' title='Danh sách tổ chức' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/employee'
        title='Tài khoản'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/employee/overview' title='Danh sách tài khoản' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Bảo hiểm</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/contract'
        title='Hợp đồng bảo hiểm'
        fontIcon='bi-archive'
        icon='note-2'
      >
        <AsideMenuItem to='/contract/overview' title='Xe cơ giới' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='' title='Tái bảo hiểm' fontIcon='bi-archive' icon='note'>
        <AsideMenuItemWithSub to='' title='Nhận tái' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItemWithSub to='' title='Nhận tái cố định' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem to='' title='GCN xe cơ giới' hasBullet={true} />
                <AsideMenuItem to='' title='Hợp đồng xe cơ giới' hasBullet={true} />
                <AsideMenuItem to='' title='Con người' hasBullet={true} />
                <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
                <AsideMenuItem to='' title='Kỹ thuật' hasBullet={true} />
                <AsideMenuItem to='' title='Trách nhiệm' hasBullet={true} />
                <AsideMenuItem to='' title='Hàng hóa' hasBullet={true} />
                <AsideMenuItem to='' title='GCN tầu' hasBullet={true} />
                <AsideMenuItem to='' title='Hợp đồng tầu' hasBullet={true} />
                <AsideMenuItem to='' title='Tỷ lệ nhận tái' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub to='' title='Nhận tái cố định mù' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem to='' title='Hợp đồng' hasBullet={true} />
                <AsideMenuItem to='' title='Phát sinh' hasBullet={true} />
                <AsideMenuItem to='' title='Mã phát sinh' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub to='' title='Nhận tái tạm thời' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem to='' title='GCN xe cơ giới' hasBullet={true} />
                <AsideMenuItem to='' title='Hợp đồng xe cơ giới' hasBullet={true} />
                <AsideMenuItem to='' title='Con người' hasBullet={true} />
                <AsideMenuItem to='' title='Tài sản' hasBullet={true} />
                <AsideMenuItem to='' title='Kỹ thuật' hasBullet={true} />
                <AsideMenuItem to='' title='Trách nhiệm' hasBullet={true} />
                <AsideMenuItem to='' title='Hàng hóa' hasBullet={true} />
                <AsideMenuItem to='' title='GCN tầu' hasBullet={true} />
                <AsideMenuItem to='' title='Hợp đồng tầu' hasBullet={true} />
                <AsideMenuItem to='' title='Tỷ lệ nhận tái' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Nhượng tái' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Nhượng tái cố định' hasBullet={true} />
            <AsideMenuItem to='' title='Chào tái tạm thời báo giá' hasBullet={true} />
            <AsideMenuItem to='' title='Chào tái tạm thời' hasBullet={true} />
            <AsideMenuItem to='' title='Nhượng tái tạm thời' hasBullet={true} />
            <AsideMenuItem to='' title='Tái tục nhượng tái' hasBullet={true} />
            <AsideMenuItem to='' title='Tổng hợp lại chuyển tái' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Báo cáo thống kê</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to=''
        title='Báo cáo nghiệp vụ'
        fontIcon='bi-archive'
        icon='questionnaire-tablet'
      >
        <AsideMenuItemWithSub to='' title='Bảng kê nộp phí' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Bảng kê nộp phí xe cơ giới' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê nộp phí các nghiệp vụ khác' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê nộp phí chưa hạch toán' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê hợp đồng xe cơ giớ' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê hợp đồng xe máy' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê hợp đồng con người' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItem to='' title='Bảng kê các hợp đồng cần thông báo thu phí' hasBullet={true} />
        <AsideMenuItemWithSub to='' title='Bảng kê các hợp đồng cần tái tục' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Bảng kê các hợp đồng cần tái tục' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê các hợp đồng cần tái tục xe' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê các hợp đồng đồng bảo hiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê các hợp đồng khác hợp đồng gốc' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê các hợp đồng sửa đổi bs' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to=''
          title='Bảng kê các hợp đồng đã phát hành hóa đơn GTGT'
          hasBullet={true}
        />
        <AsideMenuItem
          to=''
          title='Bảng kê các hợp đồng chưa phát hành hóa đơn GTGT'
          hasBullet={true}
        />
        <AsideMenuItemWithSub to='' title='Báo cáo công nợ' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Công nợ phí bảo hiểm theo khách hàng' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ phí giám định' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ hoa hồng đại lý' hasBullet={true} />
            <AsideMenuItem to='' title='Công nợ bồi thường' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê các hợp đồng còn nợ theo cán bộ' hasBullet={true} />
            <AsideMenuItemWithSub to='' title='Bảng kê xác nhận nợ' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem
                  to=''
                  title='Bảng kê xác nhận nợ phí bảo hiểm gốc'
                  hasBullet={true}
                />
                <AsideMenuItem to='' title='Bảng kê xác nhận nợ bồi thường' hasBullet={true} />
                <AsideMenuItem to='' title='Bảng kê xác nhận nợ hoa hồng' hasBullet={true} />
                <AsideMenuItem to='' title='Bảng kê xác nhận nợ giám định' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub
          to=''
          title='Báo cáo hợp đồng chưa phát sinh trách nhiệm'
          hasBullet={true}
        >
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo tổng hợp hợp đồng chưa phát sinh trách nhiệm'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo chưa phát sinh trách nhiệm quá hạn thanh toán'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết hợp đồng chưa phát sinh trách nhiệm'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo hợp đồng chưa phát sinh trách nhiệm theo cán bộ'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo hợp đồng chưa phát sinh trách nhiệm theo đại lý'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo hợp đồng chưa phát sinh trách nhiệm hạn thanh toán'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo hợp đồng chưa phát sinh trách nhiệm hạn thanh toán theo kỳ'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItem to='' title='Báo cáo nhanh tình hình khai thác nghiệp vụ' hasBullet={true} />
        <AsideMenuItemWithSub
          to=''
          title='Báo cáo nhanh tình hình bồi thường nghiệp vụ'
          hasBullet={true}
        >
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo nhanh tình hình bồi thường nghiệp vụ'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo nhanh tổng hợp chi tiết bồi thường'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo thống kê nghiệp vụ hàng hóa' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo khai thác nghiệp vụ hàng hóa' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo bồi thường nghiệp vụ hàng hóa' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo khai thác nghiệp vụ hàng hóa theo cảng'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê đơn bảo hiểm hàng hóa phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê sửa đổi bổ sung đơn bảo hiểm hàng hóa'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo thống kê bồi thường nghiệp vụ hàng hóa'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bảng kê tổn thất đã phát sinh nhưng chưa bồi thường xong'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo thống kê nghiệp vụ tàu thuyền' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo khai thác nghiệp vụ tàu thuyền' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo bồi thường nghiệp vụ tàu thuyền' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Bảng thống kê đơn bảo hiểm tàu thuyền phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê sửa đổi bổ sung đơn bảo hiểm tàu thuyền phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo thống kê bồi thường nghiệp vụ tàu thuyền'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bảng kê tổn thất đã phát sinh nhưng chưa bồi thường xong'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo thống kê nghiệp vụ xe cơ giới' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo tình hình triển khai bảo hiểm TNDS bắt buộc của chủ xe'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo tình hình triển khai bảo hiểm tự nguyện xe cơ giới'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo doanh thu theo nhóm xe' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo bồi thường theo nhóm xe' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo doanh thu và bồi thường gốc bảo hiểm xe cơ giới'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bảo hiểm bắt buộc TNDS của chủ xe cơ giới'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo tốc độ tăng trưởng doanh thu xe cơ giới'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê đơn bảo hiểm xe cơ giới phát sinh trong tháng'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo TNDS của hiệp hội bảo hiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo thống kê khai thác xe cơ giới' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo thống kê nghiệp vụ tài sản' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo khai thác nghiệp vụ tài sản' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Bảng thống kê bồi thường nghiệp vụ bảo hiểm'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê tổn thất đang giải quyết bồi thường nghiệp vụ bảo hiểm'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê đơn bảo hiểm tài sản phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê sửa đổi bổ sung đơn bảo hiểm tài sản phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Bảng kê bồi thường bảo hiểm tài sản' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Bảng thống kê tổn thất phát sinh nhưng chưa giải quyết'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo thống kê nghiệp vụ kỹ thuật' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo khai thác nghiệp vụ kỹ thuật' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Bảng thống kê bồi thường nghiệp vụ bảo hiểm'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê tổn thất đang giải quyết bồi thường nghiệp vụ bảo hiểm'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo thống kê nghiệp vụ trách nhiệm' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo khai thác nghiệp vụ trách nhiệm' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Bảng thống kê bồi thường nghiệp vụ trách nhiệm'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê tổn thất đang giải quyết bồi thường nghiệp vụ bảo hiểm'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê đơn bảo hiểm trách nhiệm phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng thống kê sửa đổi bổ sung đơn bảo hiểm trách nhiệm phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Bảng kê bồi thường bảo hiểm trách nhiệm' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Bảng thống kê tổn thất phát sinh nhưng chưa giải quyết'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo khai thác nghiệp vụ con người' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo thống kê khai thác nghiệp vụ con người'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo thống kê khai thác nghiệp vụ con người lẻ - DRM'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo khai thác nghiệp vụ xe 2 bánh' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo thống kê khai thác nghiệp vụ xe 2 bánh'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to='' title='Báo cáo kinh doanh' fontIcon='bi-archive' icon='document'>
        <AsideMenuItemWithSub to='' title='Báo cáo doanh thu' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo tổng hợp doanh thu bảo hiểm gốc' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi tiết doanh thu bảo hiểm gốc' hasBullet={true} />
            <AsideMenuItem to='' title='Doanh thu phát sinh theo hợp đồng' hasBullet={true} />
            <AsideMenuItem to='' title='Doanh thu bán hàng theo hợp đồng' hasBullet={true} />
            <AsideMenuItem to='' title='Doanh thu thực thu theo hợp đồng' hasBullet={true} />
            <AsideMenuItem to='' title='Doanh thu nhận trước' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo doanh thu đại lý tổng' hasBullet={true} />
            <AsideMenuItemWithSub to='' title='Báo cáo hủy hợp đồng' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem to='' title='Báo cáo hủy hợp đồng' hasBullet={true} />
                <AsideMenuItem to='' title='Báo cáo hủy giấy chứng nhận' hasBullet={true} />
                <AsideMenuItem to='' title='Danh sách xe giấy chứng nhận hủy' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to=''
              title='Báo cáo doanh thu chi tiết có phí chuyển tái'
              hasBullet={true}
            >
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem
                  to=''
                  title='Báo cáo chi tiết doanh thu phát sinh'
                  hasBullet={true}
                />
                <AsideMenuItem to='' title='Báo cáo chi tiết doanh thu bán hàng' hasBullet={true} />
                <AsideMenuItem to='' title='Báo cáo chi tiết doanh thu thực thu' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to=''
              title='Báo cáo tổng hợp doanh thu có phí chuyển tái theo N.vụ'
              hasBullet={true}
            >
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem
                  to=''
                  title='Báo cáo tổng hợp doanh thu phát sinh'
                  hasBullet={true}
                />
                <AsideMenuItem to='' title='Báo cáo tổng hợp doanh thu bán hàng' hasBullet={true} />
                <AsideMenuItem to='' title='Báo cáo chi tiết doanh thu thực thu' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to=''
              title='Báo cáo tổng hợp doanh thu có phí chuyển tái theo Đ.vị'
              hasBullet={true}
            >
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem
                  to=''
                  title='Báo cáo chi tiết doanh thu phát sinh'
                  hasBullet={true}
                />
                <AsideMenuItem to='' title='Báo cáo chi tiết doanh thu bán hàng' hasBullet={true} />
                <AsideMenuItem to='' title='Báo cáo chi tiết doanh thu thực thu' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub to='' title='Báo cáo Bancassurance' hasBullet={true}>
              <div style={{paddingLeft: 10}}>
                <AsideMenuItem to='' title='Doanh thu chi tiết' hasBullet={true} />
                <AsideMenuItem to='' title='Doanh thu nhóm theo nghiệp vụ' hasBullet={true} />
                <AsideMenuItem to='' title='Doanh thu nhóm theo đơn vị' hasBullet={true} />
                <AsideMenuItem to='' title='Doanh thu tổng hợp' hasBullet={true} />
                <AsideMenuItem to='' title='Đối chiếu doanh thu' hasBullet={true} />
              </div>
            </AsideMenuItemWithSub>
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo hoa hồng' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo hoa hồng theo doanh thu thực' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo doanh thu phát sinh theo đại lý' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo doanh thu bán hàng theo đại lý' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo hoa hồng đã duyệt' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo hỗ trợ đã duyệt' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo tổng hợp hoa hồng phát sinh' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi trả đại lý' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi trả đại lý tổng hợp' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo tổng hợp hoa hồng đại lý phát sinh'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết hoa hồng đại lý đã thanh toán'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết hoa hồng đại lý đã duyệt chưa thanh toán'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo tổng hợp hoa hồng đại lý chưa được duyệt'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết hoa hồng đại lý chưa được duyệt'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo tổng hợp hoa hồng hỗ trợ phát sinh'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo bồi thường' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo tổng hợp bồi thường theo nghiệp vụ'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo chi tiết bồi thường theo hồ sơ' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết hồ sơ bồi thường chưa giải quyết'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết hồ sơ bồi thường đã giải quyết'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo theo năm phát sinh hợp đồng' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường đã giải quyết có thu đòi đồng, thu đòi tái'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường chưa giải quyết có thu đòi đồng, thu đòi tái'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường đã giải quyết có thu đòi đồng, thu đòi tái theo N,vụ'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường chưa giải quyết có thu đòi đồng, thu đòi tái theo N.vụ'
              hasBullet={true}
            />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo giám định' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Bảng liệt kê chi tiết chứng từ hoa hồng' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi hoa hồng theo nghiệp vụ' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo thu đòi người thứ 3' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi giám định' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo liệt kê hủy' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItemWithSub to='' title='Báo cáo kế toán' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo công nợ tổng hợp khách hàng kế toán'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo công nợ chi tiết khách hàng kế toán'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo tổng hợp thu tiền theo năm phát sinh hợp đồng'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết thu tiền theo năm phát sinh hợp đồng'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo thu hồi công nợ' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo thực thu theo thời điểm ghi nhận doanh thu bán hàng'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Bảng thuyết minh khấu trừ thuế' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Bảng kê hóa đơn chứng từ hàng hóa, dịch vụ bán ra'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng kê hóa đơn chứng từ hàng hóa, dịch vụ bán ra theo ngày báo cáo'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng kê hóa đơn chứng từ hàng hóa, dịch vụ bán ra chưa nhập số hóa đơn'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Bảng kê hóa đơn theo ngày' hasBullet={true} />
            <AsideMenuItem to='' title='Bảng kê hóa đơn thu hồi bồi thường' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo dự phòng công nợ khó đòi' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo dự phòng công nợ khó đòi tồn đọng'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo dự phòng công nợ khó đòi 6 tháng'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo công nợ đã phát hành hóa đơn' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo thanh toán với cấp trên' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Tình hình thực hiện nghĩa vụ với nhà nước'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo doanh thu bảo hiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo doanh thu bảo hiểm gốc' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo doanh thu bảo hiểm nhận trước' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi phí bảo hiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo bồi thường bảo hiểm gốc' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi hoa hồng bảo hiểm gốc' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo chi phí quản lý hợp đồng(Đồng bảo hiểm)'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo chi phí giám định bảo hiểm gốc' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi đề phòng hạn chế tổn thất' hasBullet={true} />
            <AsideMenuItem to='' title='Chi đánh giá rủi ro đối tượng bảo hiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi quản lý văn phòng' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi bán hàng' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi quản lý, bán hàng' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo hoạt động tài chính' hasBullet={true} />
            <AsideMenuItem to='' title='Doanh thu' hasBullet={true} />
            <AsideMenuItem to='' title='Chi phí' hasBullet={true} />
            <AsideMenuItem to='' title='Doanh mục khác' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo nhận tái bảo hiểm' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo nhượng tái bảo hiểm' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='' title='Báo cáo Bộ Tài chính' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo kết quả hoạt động tháng(1_PNT)' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo doanh thu phí bảo hiểm(2_PNT)' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chỉ tiêu kinh tế(3_PNT)' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo bồi thường bảo hiểm(4_PNT)' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo dự phòng nghiệp vụ_luu' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo dự phòng nghiệp vụ' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết số dư tài khoản công nợ(Nghiệp vụ)'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo chi tiết' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo tổng hợp' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='' title='Báo cáo quản trị' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem
              to=''
              title='Báo cáo doanh thu tổng hợp theo đơn vị_M'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo doanh thu tổng hợp theo nghiệp vụ_M'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo doanh thu tổng hợp nghiệp vụ nhóm theo đơn vị_M'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường tổng hợp theo đơn vị_M'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường tổng hợp theo nghiệp vụ_M'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường tổng hợp nghiệp vụ nhóm theo đơn vị_M'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo tổng hợp doanh thu' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo doanh thu tổng hợp theo đơn vị' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo doanh thu tổng hợp theo nghiệp vụ'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo bồi thường tổng hợp theo đơn vị' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo bồi thường tổng hợp theo nghiệp vụ'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Bảng kê các hồ sơ bồi thường cùng hợp đồng cùng ngày xảy ra'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo bồi thường tăng giảm' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết bồi thường chưa giải quyết'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo chi tiết bồi thường chưa giải quyết xe cơ giới'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo phân tích công nợ quý, tháng trước'
              hasBullet={true}
            />
            <AsideMenuItem
              to=''
              title='Báo cáo phân tích công nợ cùng kỳ năm trước'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo tổng hợp công nợ quá hạn' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi tiết công nợ quá hạn' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo chi tiết tuổi nợ' hasBullet={true} />
            <AsideMenuItem
              to=''
              title='Báo cáo nhanh tổng hợp tình hình bồi thường theo đại lý'
              hasBullet={true}
            />
            <AsideMenuItem to='' title='Báo cáo tổng hợp kinh doanh' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo quản trị tổng hợp' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='' title='Báo cáo kế hoạch' hasBullet={true}>
          <div style={{paddingLeft: 10}}>
            <AsideMenuItem to='' title='Báo cáo kế hoạch doanh thu' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo kế hoạch bồi thường' hasBullet={true} />
            <AsideMenuItem to='' title='Báo cáo tính toán hiệu quả kinh doanh' hasBullet={true} />
          </div>
        </AsideMenuItemWithSub>
        <AsideMenuItem to='' title='Báo cáo tái bảo hiểm' hasBullet={true} />
      </AsideMenuItemWithSub> */}
    </div>
  )
}
