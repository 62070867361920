import {FC, createContext, useContext, useState} from 'react'
import {WithChildren} from '@/_metronic/helpers'

type AppContextProps = {
  dialog?: DialogProps
  showDialog: (props: DialogProps) => void
  hideDialog: () => void
}

type DialogProps = {
  display?: boolean
  title?: string
  content?: string | Element | any
  footer?: Element
  size?: 'sm' | 'lg' | 'xl'
}

const initAppContextPropsState = {
  showDialog: () => {},
  hideDialog: () => {},
  size: 'md',
}

const AppContext = createContext<AppContextProps>(initAppContextPropsState)

const useApp = () => useContext(AppContext)

const AppProvider: FC<WithChildren> = ({children}) => {
  const [dialog, setDialog] = useState<DialogProps>({
    display: false,
  })

  const showDialog = (props: DialogProps) => {
    setDialog({
      ...dialog,
      ...props,
      display: true,
    })
  }

  const hideDialog = () => {
    setDialog({
      display: false,
    })
  }

  return (
    <AppContext.Provider
      value={{
        dialog,
        showDialog,
        hideDialog,
      }}
    >
      <>{children}</>
    </AppContext.Provider>
  )
}

export {AppProvider, useApp}
