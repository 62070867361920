const QUERIES = {
  USERS_LIST: 'users-list',
  EMPLOYEES_LIST: 'employee-list',
  COMPENSATION_REQUESTS_LIST: 'compensation-requests-list',
  ORGANIZATIONS_LIST: 'organization-list',
  ORGANIZATION_TYPES_LIST: 'organization_type-list',
  ORGANIZATION_STRUCTURE: 'organization-structure',
  PROVINCE_LIST: 'province-list',
  ROLES_LIST: 'role-list',
  DEPARTMENT_LIST: 'department-list',
  SOURCES_LIST: 'source-list',
  CHANNELS_LIST: 'channel-list',
  PROJECTS_LIST: 'project-list',
  AIRLINE_LIST: 'airline-list',
  AIRPORT_LIST: 'airport-list',
  HOSPITAL_LIST: 'hospital-list',
  ADDRESS_LIST: 'address-list',
  UNIT_LIST: 'unit-list',
  BANK_LIST: 'bank-list',
  CONTRACT_LIST: 'contract-list',
  TRAVEL_CONTRACT_LIST: 'travel-contract',
  CONFIGURATION_DETAIL: 'configuration',
  EMAIL_TEMPLATE_LIST: 'email-template',
  EMAIL_TEMPLATE_TYPE: 'email-template-type',
  SOURCES_CURRENT: 'source-current',
  PROMOTION_LIST: 'promotion-list',
  COMMISSION_LIST: 'commission-list',
  AMENDMENT_REQUEST_LIST: 'amendment-request-list',
}

export {QUERIES}
