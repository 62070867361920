import {useEffect} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardPage = () => {
  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return <></>
}

const DashboardWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>DASHBOARD</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
