/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid h-100'
      style={{alignItems: 'center', background: '#FFFFFF'}}
    >
      {/* begin::Body */}
      <div
        className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'
        style={{position: 'relative', zIndex: 1}}
      >
        {/* begin::Form */}
        <div
          className='d-flex flex-center flex-column flex-lg-row-fluid'
          style={{justifyContent: 'flex-start'}}
        >
          {/* begin::Wrapper */}
          <div className='w-lg-600px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{
          backgroundImage: `url(https://phwi-static.s3.ap-southeast-1.amazonaws.com/1696817482117-954441302.png)`,
          position: 'absolute',
          bottom: 0,
          right: 0,
          height: '50%',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      ></div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
