import {FC, useState, useEffect, createContext, useContext} from 'react'

import {LayoutSplashScreen} from '@/_metronic/layout/core'
import {WithChildren} from '@/_metronic/helpers'
import {EventBus} from '@/app/core/EventBus'

import {OrganizationSelected} from '../components/OrganizationSelected'
import {getUserOrganizations, getUserPermissions} from './_requests'
import {AuthModel, UserOrganizationModel, UserPermission} from './_models'
import * as authHelper from './AuthHelpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  logout: () => void
  switchOrganization?: () => void
  permitAllow: (m: string, a: string | string[]) => boolean
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  logout: () => {},
  permitAllow: () => false,
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => useContext(AuthContext)

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [permissions, setPermissions] = useState<UserPermission | undefined>()

  useEffect(() => {
    if (auth?.selectedOrganization) {
      getUserPermissions(auth.selectedOrganization as string)
        .then((res) => res.data.data)
        .then((data) => {
          setPermissions(data?.module)
        })
        .catch()
    }
  }, [auth?.selectedOrganization])

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
  }

  const switchOrganization = () => {
    saveAuth({...auth, user: {...auth?.user, selectedOrganization: undefined}} as AuthModel)
  }

  const permitAllow = (moduleKey: string, actionKey: string | string[]): boolean => {
    if (!permissions) return false
    return actionKey instanceof Array
      ? Object.keys(permissions[moduleKey] || {}).some((a) => actionKey.includes(a))
      : !!permissions[moduleKey]?.[actionKey]
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, logout, switchOrganization, permitAllow}}>
      <>{children}</>
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, saveAuth, logout} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [showOrganizationSelect, setshowOrganizationSelect] = useState(false)

  useEffect(() => {
    EventBus.on(
      'logout',
      () => {
        logout()
      },
      {once: true}
    )

    return () => {
      EventBus.off('logout', () => {})
    }
  }, [])

  useEffect(() => {
    if (!auth) {
      setShowSplashScreen(false)
      setshowOrganizationSelect(false)
    } else if (auth.user && !auth.user.organizations) {
      const user = auth.user
      setShowSplashScreen(true)
      getUserOrganizations(user?.phone!)
        .then(({data}) => data.data)
        .then((organizations) => {
          user.organizations = organizations
          const domain = window.location.hostname
          const selectedOrganization =
            organizations?.length === 1
              ? organizations[0]
              : organizations.find((o) => o.domain == domain)
          if (selectedOrganization) {
            auth.selectedOrganization = selectedOrganization.id
            saveAuth({...auth, user})
          } else {
            saveAuth({...auth, organizations})
          }
        })
        .catch()
        .finally(() => setShowSplashScreen(false))
    } else if (!auth.selectedOrganization) {
      setshowOrganizationSelect(true)
    } else {
      setshowOrganizationSelect(false)
      setShowSplashScreen(false)
    }
  }, [auth])

  return showOrganizationSelect ? (
    <OrganizationSelected />
  ) : showSplashScreen && window.location.pathname !== '/auth' ? (
    <LayoutSplashScreen />
  ) : (
    <>{children}</>
  )
}

const useCurrentOrganization = (): UserOrganizationModel | undefined => {
  const {auth} = useAuth()
  return auth?.user.organizations?.find((o) => o.id === auth?.selectedOrganization)
}

export {AuthProvider, AuthInit, useAuth, useCurrentOrganization}
