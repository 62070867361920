/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {ErrorMessage, Field, Form, FormikProvider, useFormik} from 'formik'
import {AxiosError} from 'axios'
import {Link, useNavigate} from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import {KTIcon} from '@/_metronic/helpers'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {FormFieldPassword} from '@/app/components/formfield-password/FormFieldPassword'
const recaptchaRef = React.createRef()
const captchaKey = process.env.REACT_APP_CAPTCHA_KEY

const loginSchema = Yup.object().shape({
  phone: Yup.string().required('Vui lòng nhập dữ liệu'),
  password: Yup.string()
    .required('Vui lòng nhập dữ liệu')
    .min(8, 'Mật khẩu không được nhỏ hơn 8 ký tự'),
})

const phoneSessionStorage = window.sessionStorage?.phone || ''
const passwordSessionStorage = window.sessionStorage?.password || ''
const initialValues = {
  phone: phoneSessionStorage ? JSON.parse(phoneSessionStorage) : '',
  password: passwordSessionStorage ? JSON.parse(passwordSessionStorage) : '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth} = useAuth()
  const [verifyCaptcha, setVerifyCaptcha] = useState('')
  const [rememberPassword, setRememberPassword] = useState(false)
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.phone, values.password)
        if (auth?.user?.mustChangePass) {
          navigate('/auth/change-password', {state: {token: auth?.token}})
        } else {
          saveAuth(auth)
          navigate('/dashboard')
        }

        if (rememberPassword) {
          window.sessionStorage.setItem('phone', JSON.stringify(values.phone))
          window.sessionStorage.setItem('password', JSON.stringify(values.password))
        } else {
          window.sessionStorage.setItem('phone', '')
          window.sessionStorage.setItem('password', '')
        }
        // const { data: user } = await getUserByToken(auth.api_token)
      } catch (error) {
        if (error instanceof AxiosError) {
          setStatus(error?.response?.data?.message)
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
          const errorCode = error?.response?.data?.code
          if (errorCode == 'INVALID_PASSWORD' || errorCode == 'ERROR') {
            return formik.setFieldError('password', error?.response?.data?.message)
          } else {
            return formik.setFieldError('phone', error?.response?.data?.message)
          }
        }
      }
    },
  })

  const onSubmitWithReCAPTCHA = async (value) => {
    // const { data: verify } = await verifyRecaptcha(value, "6LeMzIgoAAAAADEcCT1oSJHEUaWPGGh_lYw9tX0M")
    setVerifyCaptcha(value)
  }

  const onExpriedCaptcha = () => {
    setVerifyCaptcha('')
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Đăng nhập</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Form group */}
        <div className='fv-row mb-8' style={{width: '70%'}}>
          <label className='form-label fs-6 text-gray-600'>Email/Số điện thoại</label>
          <Field
            type='text'
            {...formik.getFieldProps('phone')}
            name='phone'
            className={'form-control bg-transparent'}
          />
          <ErrorMessage name='phone' component='span' className='message-invalid' />
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3' style={{width: '70%'}}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <label className='form-label text-gray-600 fs-6 mb-0'>Mật khẩu</label>
          </div>
          <FormFieldPassword
            {...formik.getFieldProps('password')}
            className={clsx('form-control bg-transparent')}
          />
          <ErrorMessage name='password' component='span' className='message-invalid' />
        </div>
        {/* end::Form group */}

        {/* begin::Wrapper */}
        <div className='d-flex flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div className='form-group'>
            <div className='checkbox-list'>
              <label className='checkbox' style={{display: 'flex', paddingTop: 20}}>
                <input
                  type='checkbox'
                  name='Checkboxes1'
                  style={{marginRight: 10}}
                  checked={rememberPassword}
                  onChange={() => setRememberPassword(!rememberPassword)}
                />
                <span></span>
                Ghi nhớ mật khẩu
              </label>
            </div>
          </div>
        </div>
        {/* end::Wrapper */}
        {!window.location.origin.startsWith('http://localhost:3011') && (
          <div className='d-grid mb-10'>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={captchaKey}
              onChange={onSubmitWithReCAPTCHA}
              onExpired={onExpriedCaptcha}
            />
          </div>
        )}
        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
            // disabled={formik.isSubmitting || !formik.isValid}
            style={{width: '50%', borderRadius: 50}}
          >
            {!loading && <span className='indicator-label'>Đăng nhập</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
        <div style={{display: 'flex', gap: 30, flexDirection: 'column'}}>
          <div>
            Bạn chưa có tài khoản ?{' '}
            <Link
              to='/auth/registration'
              className='link-dark'
              style={{textDecoration: 'underline'}}
            >
              Đăng ký
            </Link>
          </div>
          <Link
            to='/auth/forgot-password'
            className='link-dark'
            style={{textDecoration: 'underline'}}
          >
            Quên mật khẩu ?
          </Link>
        </div>
      </Form>
    </FormikProvider>
  )
}
